



























































































































import { Component, Vue } from "vue-property-decorator";
import { IUserProfile, IUserProfileUpdate } from "@/interfaces";
import { dispatchGetUsers, dispatchUpdateUser } from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";
import _ from "lodash";

@Component({
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
  watch: {
    user: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      const call = _.get(this, "reset", () => null);
      call();
    },
  },
})
export default class EditUser extends Vue {
  public valid = true;
  public firstName: string = "";
  public lastName: string = "";
  public phone: string = "";
  public email: string = "";
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";
  public referral_items = [
    "Building Admin",
    "Online Ads",
    "Flyers",
    "Google Search",
    "Referred by Friend",
    "Referred by Broker",
    "Sales Booth",
    "Other",
  ];
  public referral_other = "";
  public referralError = "";
  public referral = "";
  public referral_cid = "";
  public mounted() {
    this.reset();
  }

  public reset() {
    this.setPassword = false;
    this.password1 = "";
    this.password2 = "";
    this.$validator.reset();
    if (this.user) {
      console.log("user", this.user);
      this.firstName = this.user.first_name;
      this.lastName = this.user.last_name;
      this.phone = this.user.phone;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_superuser;
      this.referral_cid = this.user.referral_cid;
      this.referral = this.user.referral;
      this.referral_other = this.user.referral_other;
    }
  }
  public checkReferral() {
    if (!this.referral) {
      this.referralError = "Please Select a Referral Source";
      return false;
    }
    return true;
  }
  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      updatedProfile.first_name = this.firstName;
      updatedProfile.last_name = this.lastName;
      updatedProfile.phone = this.phone;
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      updatedProfile.referral_cid = this.referral_cid;
      updatedProfile.referral = this.referral;
      updatedProfile.referral_other = this.referral_other;
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      this.$props.onClose(this.user.id);
    }
  }

  get user() {
    return this.$props.user;
  }
}
